<template>
  <ion-page>
    <Header title="" />
    <ion-content :fullscreen="true">
      <div class="container">
        <!--  -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header
  }
})
</script>

<style>

</style>
